import React from 'react';

const DEFAULT_NOTIFICATIONS = [
    { imgSrc: "./assets/images/people-info.svg", sender: "System", message: "You have no booking till now. Please explore." },
    { imgSrc: "./assets/images/people-info.svg", sender: "System", message: "Welcome to PartyConnectHub. Explore and Book your favorite party." }
];

const NotificationItem = ({ imgSrc, sender, message }) => (
  <div className="tw-bg-[#393737] tw-rounded-2xl tw-p-4 tw-mb-4 tw-flex tw-items-start">
    <img src={imgSrc} alt={`${sender} icon`} className="tw-w-12 tw-h-12 tw-mr-4 tw-rounded-full tw-flex-shrink-0" />
    <div className="tw-flex-grow">
      <h2 className="tw-text-orange-500 tw-font-semibold tw-text-left">{sender}</h2>
      <p className="tw-text-white tw-text-sm tw-text-left">{message}</p>
    </div>
  </div>
);

const Notification = ({ notifications = [] }) => {
  const notificationsToShow = notifications.length ? notifications : DEFAULT_NOTIFICATIONS;

  return (
    <div className="tw-text-white tw-flex tw-flex-col tw-justify-between tw-items-center sm:tw-px-6 lg:tw-px-8 tw-py-8">
      <div className="tw-w-full tw-max-w-md tw-flex tw-flex-col tw-items-center">
        <h1 className="tw-text-2xl tw-font-bold tw-text-center tw-mb-8">Notification</h1>
        <div className="tw-w-full tw-overflow-y-auto">
          {notificationsToShow.map((notification, index) => (
            <NotificationItem
              key={index}
              imgSrc={notification.imgSrc}
              sender={notification.sender}
              message={notification.message}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Notification;

