import React from 'react';
import { Outlet } from 'react-router-dom';
import NavBar from './NavBar';
import Header from './Header';

const MainLayout = () => {
  return (
    <>
      <Header />
      <div className="template">
        <div className="page-wrapper">
          <div className="container">
            <Outlet />
          </div>
        </div>
      </div>
      <NavBar />
    </>
  );
};

export default MainLayout;
