import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import useRequest from '../../hooks/useRequest'
import { showToast, toastType } from '../../hooks/useToast'
import _ from 'lodash'
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { auth } from '../../helpers/firebase'

const LoginPage = () => {

    const [searchParams, setSearchParams] = useSearchParams()
    const provider = new GoogleAuthProvider();

    const [email, setEmail] = useState('')
    const [role, setRole] = useState('guest')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [error, setError] = useState({ email: false, password: false })

    const { makeRequest } = useRequest()

    useEffect(() => {
        const token = localStorage.getItem('token')
        if (token) {
            window.location.assign(`/home`)
        }
        handleRoleSelection(searchParams.get('role') ?? 'guest')
    }, [])

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!email || !password) {
            return setError({
                email: !email,
                password: !password,
            })
        }

        makeRequest({
            url: '/api/auth/login',
            method: 'POST',
            body: { email, password, role },
            onSuccess: ({ token, user }) => {
                localStorage.setItem('token', token)
                localStorage.setItem('user', JSON.stringify({ userId: user.id, role: user.role }))
                localStorage.setItem('role', user.role)
                window.location.assign(`/home`)
                showToast('Login Successful', toastType.SUCCESS)
            },
            onFailure: (error) => {
                showToast(error?.message ?? 'Error', toastType.ERROR)
            },
        })
    }

    const handleRoleSelection = (newRole) => {
        setRole(newRole);
        setSearchParams({ role: newRole });
    }

    const signInWithGoogle = async () => {
        try {
            const result = await signInWithPopup(auth, provider);

            const user = result.user;

            if (!user || !user.stsTokenManager?.accessToken) {
                return showToast('Something went wrong', toastType.ERROR)
            }

            makeRequest({
                url: '/api/auth/google-login',
                method: 'POST',
                body: { token: user.stsTokenManager?.accessToken, role },
                onSuccess: ({ token, user }) => {
                    localStorage.setItem('token', token)
                    localStorage.setItem('user', JSON.stringify({ userId: user.id, role: user.role }))
                    localStorage.setItem('role', user.role)
                    window.location.assign(`/home`)
                    showToast('Login Successful', toastType.SUCCESS)
                },
                onFailure: (error) => {
                    showToast(error?.message ?? 'Error', toastType.ERROR)
                },
            })
        } catch (error) {
            showToast("Error during sign-in:", toastType.ERROR);
        }
    };

    return (
        <div className=" tw-bg-black tw-flex tw-flex-col tw-justify-center tw-items-center tw-px-4 sm:tw-px-6 lg:tw-px-8">
            <img
                src="/assets/images/party-connect-hub.svg"
                alt="PartyConnectHub Logo"
                width="100"
            />
            <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/765ef6ba9309e06eaa38db9ca7f6785fa758cdadc0b4575d496e2fac9132a7b8?"
                alt="Party Connect Hub"
                className="tw-items-center tw-my-4"
                width="150"
            />

            <h1 className="tw-text-white tw-text-center tw-text-3xl tw-font-bold tw-mb-2">
                PartyConnectHub
            </h1>

            <p className="tw-text-gray-300 tw-text-center tw-mb-4">
                Discover and join exciting parties.
            </p>

            <form onSubmit={handleSubmit} className="tw-w-full tw-max-w-sm tw-flex tw-flex-col tw-gap-4">
                <div className="tw-flex tw-justify-center tw-gap-4 tw-mb-2">
                    <button
                        type="button"
                        className={`tw-px-4 tw-py-2 tw-rounded-full tw-font-semibold ${role === 'guest' ? 'tw-bg-orange-500 tw-text-white' : 'tw-bg-gray-700 tw-text-gray-300'}`}
                        onClick={() => handleRoleSelection('guest')}
                    >
                        Guest
                    </button>
                    <button
                        type="button"
                        className={`tw-px-4 tw-py-2 tw-rounded-full tw-font-semibold ${role === 'host' ? 'tw-bg-orange-500 tw-text-white' : 'tw-bg-gray-700 tw-text-gray-300'}`}
                        onClick={() => handleRoleSelection('host')}
                    >
                        Host
                    </button>
                </div>

                <div>
                    <input style={{ backgroundColor: '#393737' }}
                        id='email'
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        className={`tw-w-full tw-py-3 tw-text-lg tw-px-6 tw-rounded-full tw-bg-[#2c2c2c] tw-text-gray-300 tw-border ${error.email ? 'tw-border-red-500' : 'tw-border-none'}
                            focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#fb880c] ${error.email ? 'tw-border-red-500' : ''}`}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {error.email && <p className="tw-text-red-500 tw-text-sm">* Enter a valid email</p>}
                </div>

                <div className="tw-relative">
                    <input style={{ backgroundColor: '#393737' }}
                        id='password'
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        placeholder="Enter your password"
                        className={`tw-w-full tw-py-3 tw-text-lg tw-px-6 tw-rounded-full tw-bg-[#2c2c2c] tw-text-gray-300 tw-border ${error.password ? 'tw-border-red-500' : 'tw-border-none'}
                            focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#fb880c] ${error.password ? 'tw-border-red-500' : ''}`}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button
                        type="button"
                        className="tw-absolute tw-right-4 tw-top-1/2 tw-transform -tw-translate-y-1/2 tw-text-gray-400 tw-cursor-pointer tw-mt-[4px]"
                        onClick={togglePasswordVisibility}
                    >
                        <ion-icon
                            name={showPassword ? 'eye-off' : 'eye'}
                            style={{ fontSize: '1.5rem' }}
                        />
                    </button>
                    {error.password && <p className="tw-text-red-500 tw-text-sm">* Enter a valid password</p>}
                </div>

                <button type="submit" className="tw-bg-orange-500 tw-text-white tw-py-3 tw-rounded-full tw-w-full tw-font-semibold hover:tw-bg-orange-600">
                    Log In as {_.startCase(role)}
                </button>

                <span className="tw-text-center tw-text-white tw-font-semibold tw-text-lg">Or</span>
                <button
                    type="button"
                    onClick={signInWithGoogle}
                    className="tw-bg-[#393737] tw-text-white tw-w-full tw-py-3 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-gap-2"
                >
                    <img src="/assets/images/google.svg" alt="Google" className="tw-h-5 tw-w-5" />
                    Continue with Google
                </button>
            </form>

            <div className="tw-text-center tw-mt-2">
                <a href={`/forgot-password?role=${role}`} className="tw-text-gray-400 hover:tw-text-white">Forgot Password?</a>
            </div>

            <div className="tw-text-center tw-text-white tw-mt-2 tw-mb-3">
                New here? <a href={`/register?role=${role}`} className="tw-font-semibold tw-text-white hover:tw-underline">Sign Up</a>
            </div>
        </div>
    )
}

export default LoginPage
