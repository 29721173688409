import React from 'react';

const scrollbarHideStyle = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
};

const StorySection = ({ stories }) => {
  return (
    <div className="tw-overflow-x-auto tw-whitespace-nowrap" style={scrollbarHideStyle}>
      <div className="tw-flex tw-space-x-4 tw-p-2">
        {stories.map((story, index) => (
          <a href={`/organizer/${story.id}/parties`} key={index} className="tw-flex tw-flex-col tw-items-center">
            <div className="tw-w-16 tw-h-16 tw-rounded-full tw-bg-gradient-to-tr tw-from-yellow-400 tw-to-pink-500 tw-p-0.5 tw-overflow-hidden">
              <img
                src={story.profile}
                alt={''}
                className="tw-w-full tw-h-full tw-object-cover tw-rounded-full"
              />
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default StorySection;
