import React, { useEffect, useState } from 'react';
import useRequest from '../../hooks/useRequest';
import { showToast, toastType } from '../../hooks/useToast';

const Register = () => {
    const [role, setRole] = useState('guest');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const role = searchParams.get('role')
        if (role) {
            setRole(role)
        }
    }, [])

    const { makeRequest } = useRequest()

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePassword = (password) => {
        return password.length >= 8;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setNameError('');
        setEmailError('');
        setPasswordError('');

        const isEmailValid = validateEmail(email);
        const isPasswordValid = validatePassword(password);

        if (name.length < 3) {
            return setNameError('Name must be at least 3 characters');
        }

        if (!isEmailValid) {
            return setEmailError('Enter a valid email');
        }

        if (!isPasswordValid) {
            return setPasswordError('Password must be at least 8 characters');
        }

        registerUser({ name, email, password, role })
    };

    const registerUser = (userData) => {
        makeRequest({
            url: '/api/auth/register',
            method: 'POST',
            body: userData,
            onSuccess: (response) => {
                window.location.assign(`/login`)
            },
            onFailure: (error) => {
                showToast(error.message, toastType.ERROR)
            },
        });
    }

    const handleBackClick = () => {
        window.history.back();
    };

    return (
        <div className="tw-bg-black tw-flex tw-flex-col tw-justify-center tw-items-center tw-px-4 sm:tw-px-6 lg:tw-px-8">
            <div className="tw-mt-2 tw-flex tw-items-center tw-w-full tw-justify-center tw-relative tw-mb-3">
                <button onClick={handleBackClick} className="tw-text-white tw-text-2xl tw-absolute tw-left-4">
                    <ion-icon name={"arrow-back-outline"} />
                </button>
                <img
                    src="/assets/images/party-connect-hub.svg"
                    alt="PartyConnectHub Logo"
                    width="100"
                />
            </div>
            <div className="tw-w-full tw-flex tw-justify-center">
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/765ef6ba9309e06eaa38db9ca7f6785fa758cdadc0b4575d496e2fac9132a7b8?"
                    alt="Party Connect Hub"
                    className="tw-items-center tw-my-2"
                    width="180"
                />
            </div>

            <h1 className="tw-text-white tw-text-center tw-text-3xl tw-font-bold tw-mb-2">
                PartyConnectHub
            </h1>

            <p className="tw-text-gray-300 tw-text-center tw-mb-6">
                Discover and join exciting Parties.
            </p>

            <form onSubmit={handleSubmit} className="tw-w-full tw-max-w-sm tw-flex tw-flex-col tw-gap-4">
                <div className="tw-flex tw-justify-center tw-gap-4 tw-mb-2">
                    <button
                        type="button"
                        className={`tw-px-4 tw-py-2 tw-rounded-full tw-font-semibold ${role === 'guest' ? 'tw-bg-orange-500 tw-text-white' : 'tw-bg-gray-700 tw-text-gray-300'}`}
                        onClick={() => setRole('guest')}
                    >
                        Guest
                    </button>
                    <button
                        type="button"
                        className={`tw-px-4 tw-py-2 tw-rounded-full tw-font-semibold ${role === 'host' ? 'tw-bg-orange-500 tw-text-white' : 'tw-bg-gray-700 tw-text-gray-300'}`}
                        onClick={() => setRole('host')}
                    >
                        Host
                    </button>
                </div>

                <div>
                    <input style={{ backgroundColor: '#393737' }}
                        type="text"
                        name="name"
                        placeholder="Enter your name"
                        className={`tw-w-full tw-py-3 tw-text-lg tw-px-6 tw-rounded-full tw-bg-[#2c2c2c] tw-text-gray-300 tw-border ${nameError ? 'tw-border-red-500' : 'tw-border-none'} focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#fb880c] ${nameError ? 'tw-border-red-500' : ''}`}
                        value={name}
                        onChange={(e) => {
                            setNameError('');
                            setName(e.target.value);
                        }}
                    />
                    {nameError && <p className="tw-text-red-500 tw-text-sm">* {nameError}</p>}
                </div>

                <div>
                    <input style={{ backgroundColor: '#393737' }}
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        className={`tw-w-full tw-py-3 tw-text-lg tw-px-6 tw-rounded-full tw-bg-[#2c2c2c] tw-text-gray-300 tw-border ${emailError ? 'tw-border-red-500' : 'tw-border-none'} focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#fb880c] ${emailError ? 'tw-border-red-500' : ''}`}
                        value={email}
                        onChange={(e) => {
                            setEmailError('');
                            setEmail(e.target.value);
                        }}
                    />
                    {emailError && <p className="tw-text-red-500 tw-text-sm">* {emailError}</p>}
                </div>

                <div>
                    <input style={{ backgroundColor: '#393737' }}
                        type="password"
                        name="password"
                        placeholder="Enter your password"
                        className={`tw-w-full tw-py-3 tw-text-lg tw-px-6 tw-rounded-full tw-bg-[#2c2c2c] tw-text-gray-300 tw-border ${passwordError ? 'tw-border-red-500' : 'tw-border-none'} focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#fb880c] ${passwordError ? 'tw-border-red-500' : ''}`}
                        value={password}
                        onChange={(e) => {
                            setPasswordError('');
                            setPassword(e.target.value);
                        }}
                    />
                    {passwordError && <p className="tw-text-red-500 tw-text-sm">* {passwordError}</p>}
                </div>

                <button
                    type="submit"
                    className="tw-bg-orange-500 tw-text-white tw-py-3 tw-rounded-full tw-w-full tw-font-semibold hover:tw-bg-orange-600"
                >
                    Sign Up as {role === 'guest' ? 'Guest' : 'Host'}
                </button>
                <p className="tw-text-gray-300 tw-text-sm tw-text-center tw-mt-0">By signing up, you confirm that you've read and agree to our <a href="/terms-and-conditions" className="tw-font-semibold tw-text-white hover:tw-underline">Terms & Conditions</a> and <a href="/privacy-policy" className="tw-font-semibold tw-text-white hover:tw-underline">Privacy Policy</a>.</p>
            </form>

            <div className="tw-text-center tw-text-white tw-mt-6">
                Already Registered? <a href={`/login?role=${role}`} className="tw-font-semibold tw-text-white hover:tw-underline">Login</a>
            </div>
        </div>
    );
};

export default Register