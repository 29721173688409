import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useRequest from '../../hooks/useRequest';
import { showToast, toastType } from '../../hooks/useToast';
import moment from 'moment';

const CUISINE_OPTIONS = [
    'Italian', 'Chinese', 'Indian', 'Mexican', 'French', 'Japanese', 'Mediterranean', 'Thai',
    'Middle Eastern', 'Korean', 'Vietnamese', 'Caribbean', 'African', 'American', 'Fusion', 'OtherCuisine',
];

const ACTIVITY_OPTIONS = [
    'Sports Screening',
    'Photo Booth',
    'Live Performances',
    'Guest Speakers',
    'Stand-up Comedy',
    'Celebrity Meetups',
    'OtherActivity',
];

const PARTY_TYPE_OPTIONS = [
    'House Party',
    'Cocktail Party',
    'Pool Party',
    'Costume Party',
    'Open Bar Party',
    'Dance Party',
    'Themed Party',
    'Garden Party',
    'BBQ Party',
    'Normal Party',
    'Networking Party',
    'Dinner Party',
    'DJ Party',
    'OtherType',
];

const VENUE_OPTIONS = [
    'Club',
    'Bar',
    'Lounge',
    'Private',
    'Residence',
    'Rooftop'
];

const CreateEditParty = () => {
    const { id } = useParams();
    const isEditing = !!id;

    const initialFormData = {
        name: '',
        address: '',
        city: '',
        venue: '',
        fees: '',
        description: '',
        date: '',
        time: '',
        activities: [],
        otherActivity: '',
        cuisines: [],
        otherCuisine: '',
        types: [],
        otherType: '',
        coverImage: null,
        gallery: [],
        menu: [],
        drinks: [],
        parkingAvailability: null,
    };

    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [expandedSection, setExpandedSection] = useState('partyDetails');

    const { makeRequest } = useRequest();
    const navigate = useNavigate();

    useEffect(() => {
        if (isEditing) {
            makeRequest({
                url: `/api/party/${id}`,
                method: 'GET',
                onSuccess: ({ party }) => {
                    setFormData({
                        name: party.name,
                        description: party.description,
                        fees: party.fees,
                        date: new Date(party.date).toISOString().split('T')[0],
                        time: party.time,
                        activities: party?.activities?.filter(a => ACTIVITY_OPTIONS.includes(a)),
                        otherActivity: party?.activities?.find(a => a === 'OtherActivity') ? party?.activities?.filter(a => !ACTIVITY_OPTIONS.includes(a)) : '',
                        cuisines: party?.cuisines?.filter(c => CUISINE_OPTIONS.includes(c)),
                        otherCuisine: party?.cuisines?.find(c => c === 'OtherCuisine') ? party?.cuisines?.find(c => !CUISINE_OPTIONS.includes(c)) : '',
                        types: party?.types?.filter(a => PARTY_TYPE_OPTIONS.includes(a)),
                        otherType: party?.types?.find(t => t === 'OtherType') ? party?.types?.find(type => !PARTY_TYPE_OPTIONS.includes(type)) : '',
                        // coverImage: party.coverImage,
                        // gallery: party?.gallery,
                        // menu: party?.menu,
                        // drinks: party?.drinks,
                        city: party?.location?.city,
                        address: party?.location?.address,
                        venue: party?.location?.venue,
                        parkingAvailability: party?.location?.parkingAvailability,
                    });
                },
                onFailure: (error) => {
                    showToast(error?.message || 'Failed to fetch party data', toastType.ERROR);
                },
            });
        }
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value, type, checked, files } = e.target;
        if (type === 'checkbox') {
            updateCheckboxState(name, value, checked);
        } else if (type === 'file') {
            updateFileState(name, files);
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };

    const updateCheckboxState = (name, value, checked) => {
        setFormData((prev) => ({
            ...prev,
            [name]: checked
                ? [...prev[name], value]
                : prev[name].filter((item) => item !== value),
        }));
    };

    const updateFileState = (name, files) => {
        setFormData((prev) => ({ ...prev, [name]: files }));
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name) newErrors.name = 'Party name is required';
        if (!formData.address) newErrors.address = 'Address is required';
        if (!formData.city) newErrors.city = 'City is required';
        if (!formData.venue) newErrors.venue = 'Venue is required';
        if (!formData.fees || formData.fees < 0 || formData.fees > 100000) newErrors.fees = 'Valid ticket price is required (0-100000)';
        if (!formData.date) newErrors.date = 'Date is required';
        if (!formData.time) newErrors.time = 'Time is required';
        if (!formData.coverImage) newErrors.coverImage = 'Cover Image is required';
        if (!formData.parkingAvailability) newErrors.parkingAvailability = 'Parking Availability is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!validateForm()) {
            showToast('Please correct the errors in the form', toastType.ERROR);
            return;
        }

        setIsSubmitting(true);

        const submitData = new FormData();
        for (const key in formData) {
            if (formData.hasOwnProperty(key) && formData[key] != null && formData[key] !== '') {
                if (Array.isArray(formData[key])) {
                    if (formData[key].length > 0) {
                        formData[key].forEach((value) => submitData.append(`${key}[]`, value));
                    }
                } else if (formData[key] instanceof FileList) {
                    if (formData[key].length > 0) {
                        Array.from(formData[key]).forEach((file) => submitData.append(key, file));
                    }
                } else {
                    submitData.append(key, formData[key]);
                }
            }
        }

        const url = isEditing ? `/api/party/${id}` : '/api/party/create';
        const method = isEditing ? 'PUT' : 'POST';

        makeRequest({
            url,
            method,
            body: submitData,
            onSuccess: () => {
                showToast(`Party ${isEditing ? 'updated' : 'created'} successfully`, toastType.SUCCESS);
                setTimeout(() => navigate('/home'), 1500);
            },
            onFailure: (error) => {
                showToast(error?.message || `Failed to ${isEditing ? 'update' : 'create'} party`, toastType.ERROR);
                setIsSubmitting(false);
            },
        });
    };

    const renderSection = (sectionName, label, icon, content) => (
        <div className="tw-mb-4">
            <div
                className={`tw-bg-[#373737] tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-3 tw-cursor-pointer ${expandedSection === sectionName
                    ? 'tw-rounded-t-md'
                    : 'tw-rounded-md'
                    }`}
                onClick={() => setExpandedSection(expandedSection === sectionName ? '' : sectionName)}
            >
                <h4 className="tw-text-lg tw-font-semibold tw-flex tw-items-center">
                    <ion-icon name={icon} class="tw-mr-2"></ion-icon>
                    {label}
                </h4>
                <ion-icon name={expandedSection === sectionName ? "chevron-up-outline" : "chevron-down-outline"}></ion-icon>
            </div>
            {expandedSection === sectionName && (
                <div className="tw-bg-[#373737] tw-p-4 tw-transition-all tw-duration-300 tw-rounded-b-md">
                    {content}
                </div>
            )}
        </div>
    );

    const renderInput = (label, name, type = 'text', required = true) => {
        let additionalProps = {};

        if (type === 'date') {
            additionalProps.min = moment().add(1, 'days').format('YYYY-MM-DD');
        } else if (type === 'time') {
            additionalProps.min = moment().format('HH:mm');
        } else if (name === 'fees') {
            additionalProps = { min: 0, max: 100000 };
        }

        return (
            <div className='tw-mb-4'>
                <label htmlFor={name} className="tw-block tw-text-lg md:tw-text-lg tw-mb-2 tw-text-left">
                    {label}
                    {required && <sup>*</sup>}
                </label>
                <input
                    id={name}
                    name={name}
                    type={type}
                    value={formData[name]}
                    onChange={handleInputChange}
                    required={required}
                    className={`tw-w-full tw-bg-[#2c2c2c] tw-text-white tw-rounded-md tw-px-4 tw-py-2 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#fb880c] ${(type === 'date' || type === 'time') ? 'tw-calendar-white' : ''}`}
                    {...additionalProps}
                />
                {errors[name] && <span className="tw-text-red-500">{errors[name]}</span>}
            </div>
        );
    };

    const renderCheckboxGroup = (label, options, selectedValues, onChangeHandler, name, required = true) => (
        <div className='tw-mb-4'>
            <label className="tw-block tw-text-lg md:tw-text-lg tw-mb-2 tw-text-left">
                {label}
                {required && <sup>*</sup>}
            </label>
            <div className="tw-space-y-2">
                {options.map((option) => (
                    <div key={option} className="tw-flex tw-items-center">
                        <input
                            type="checkbox"
                            id={option}
                            name={name}
                            value={option}
                            checked={selectedValues?.includes(option)}
                            onChange={onChangeHandler}
                            className="tw-mr-2 tw-h-4 tw-w-4"
                        />
                        <label htmlFor={option}>{option.replace('Other', 'Other (Specify)')}</label>
                    </div>
                ))}
            </div>
        </div>
    );

    const renderSelectInput = (label, name, options, required = true) => (
        <div className='tw-mb-4'>
            <label htmlFor={name} className="tw-block tw-text-lg md:tw-text-lg tw-mb-2 tw-text-left">
                {label}
                {required && <sup>*</sup>}
            </label>
            <select
                id={name}
                name={name}
                value={formData[name]}
                onChange={handleInputChange}
                required={required}
                className="tw-w-full tw-bg-[#2c2c2c] tw-rounded-md tw-px-3 tw-py-3 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#fb880c]"
            >
                <option value="">Select {label}</option>
                {options.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
            {errors[name] && <span className="tw-text-red-500">{errors[name]}</span>}
            <style>{`
            select option:hover {
                background-color: #fb880c !important;
                color: white;
            }
        `}</style>
        </div>
    );

    const renderFileInput = (label, name, isMultiple = false, required = false) => (
        <div className='tw-mb-4'>
            <label htmlFor={name} className="tw-block tw-text-lg md:tw-text-lg tw-mb-2 tw-text-left">
                {label}
                {required && <sup>*</sup>}
            </label>
            <input
                id={name}
                name={name}
                type="file"
                multiple={isMultiple}
                onChange={handleInputChange}
                required={required}
                accept=".jpg,.png,.jpeg"
                className="tw-w-full tw-text-white tw-bg-[#2c2c2c] tw-rounded-md tw-px-4 tw-py-2 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#fb880c]"
            />
            {formData[name] && formData[name].length > 0 && (
                <div className="tw-mt-2 tw-text-sm tw-text-gray-300 tw-text-left">
                    {console.log(Array.from(formData[name]))}
                    Selected files: {Array.from(formData[name]).map(file => file.name).join(', ')}
                </div>
            )}
            {errors[name] && <span className="tw-text-red-500">{errors[name]}</span>}
        </div>
    );

    const handleBackClick = () => {
        window.history.back();
    };

    return (
        <div className="tw-min-h-screen tw-text-white tw-p-4 md:tw-p-8">
            <div className="tw-max-w-4xl tw-mx-auto">
                <h1 className="tw-text-xl md:tw-text-2xl tw-font-semibold tw-text-left tw-mt-4 tw-mb-4 tw-flex tw-items-center">
                    <button onClick={handleBackClick} className="tw-text-white tw-text-2xl tw-flex tw-items-center tw-mr-4">
                        <ion-icon name="arrow-back-outline"></ion-icon>
                    </button>
                    {isEditing ? 'Edit Party' : 'Create Party'}
                </h1>

                <form onSubmit={handleSubmit} className="tw-space-y-6">
                    {renderSection('partyDetails', 'Party Details', 'person-outline', (
                        <>
                            {renderInput('Party Name', 'name')}
                            {renderInput('Description', 'description', 'textarea')}
                            {renderCheckboxGroup('Party Type', PARTY_TYPE_OPTIONS, formData.types, handleInputChange, 'types')}
                            {formData.types?.includes('OtherType') && renderInput('Other Party Type', 'otherType', 'text', false)}
                        </>
                    ))}
                    {renderSection('venueDetails', 'Venue Details', 'location-outline', (
                        <>
                            {renderSelectInput('Venue', 'venue', VENUE_OPTIONS)}
                            {renderInput('Address', 'address')}
                            {renderInput('City', 'city')}
                            {renderSelectInput('Parking Availability', 'parkingAvailability', ['Yes', 'No'], true)}
                        </>
                    ))}

                    {renderSection('scheduleTiming', 'Schedule & Timing', 'time-outline', (
                        <>
                            {renderInput('Schedule Date', 'date', 'date')}
                            {renderInput('Time of Party', 'time', 'time')}
                        </>
                    ))}

                    {renderSection('pricingTickets', 'Pricing & Tickets', 'ticket-outline', (
                        <>
                            {renderInput('Ticket Price', 'fees', 'number')}
                        </>
                    ))}

                    {renderSection('activitiesEntertainment', 'Activities & Entertainment', 'musical-notes-outline', (
                        <>
                            {renderCheckboxGroup('Activities Offered', ACTIVITY_OPTIONS, formData.activities, handleInputChange, 'activities', false)}
                            {formData.activities?.includes('OtherActivity') && renderInput('Other Activity', 'otherActivity', 'text', false)}
                        </>
                    ))}

                    {renderSection('foodDrink', 'Food & Drink', 'restaurant-outline', (
                        <>
                            {renderCheckboxGroup('Cuisine Type', CUISINE_OPTIONS, formData.cuisines, handleInputChange, 'cuisines')}
                            {formData.cuisines?.includes('OtherCuisine') && renderInput('Other Cuisine', 'otherCuisine', 'text', false)}
                            {renderFileInput('Food Menu', 'menu', true)}
                            {renderFileInput('Drinks Menu', 'drinks', true)}
                        </>
                    ))}

                    {renderSection('mediaBranding', 'Media & Branding', 'camera-outline', (
                        <>
                            {renderFileInput('Cover Image', 'coverImage', false, !isEditing)}
                            {renderFileInput('Gallery', 'gallery', true)}
                        </>
                    ))}

                    <div>
                        <button
                            type="submit"
                            className={`tw-w-full tw-mb-16 tw-bg-orange-500 hover:tw-bg-orange-600 tw-text-white tw-text-xl md:tw-text-2xl tw-py-3 tw-rounded-full tw-mt-2 ${isSubmitting && 'tw-opacity-50 tw-pointer-events-none'
                                }`}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? (isEditing ? 'Updating...' : 'Creating...') : (isEditing ? 'Update Party' : 'Create Party')}
                        </button>
                    </div>

                </form>
            </div>
            <style>{`
                .tw-calendar-white::-webkit-calendar-picker-indicator {
                    filter: invert(1);
                }

                /* Custom checkbox styles */
                input[type="checkbox"] {
                    -webkit-appearance: none;
                    appearance: none;
                    width: 20px;
                    height: 20px;
                    border: 2px solid #fb880c;
                    border-radius: 4px;
                    outline: none;
                    cursor: pointer;
                    position: relative;
                }

                input[type="checkbox"]:checked {
                    background-color: #fb880c;
                }

                input[type="checkbox"]:checked::after {
                    content: '';
                    position: absolute;
                    left: 6px;
                    top: 2px;
                    width: 6px;
                    height: 12px;
                    border: solid black;
                    border-width: 0 3px 3px 0;
                    transform: rotate(45deg);
                }
            `}</style>
        </div>
    );
};

export default CreateEditParty;
