import React from 'react';

const ImageViewer = ({ imageUrl, onClose }) => {
  return (
    <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-75 tw-flex tw-items-center tw-justify-center tw-z-50">
      <div className="tw-relative tw-max-w-3xl tw-max-h-full tw-p-4">
        <img src={imageUrl} alt="Full size" className="tw-max-w-full tw-max-h-[90vh] tw-object-contain" />
        <button 
          onClick={onClose} 
          className="tw-absolute tw-top-2 tw-right-2 tw-text-white tw-text-2xl tw-bg-black tw-bg-opacity-50 tw-rounded-full tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center"
        >
          &times;
        </button>
      </div>
    </div>
  );
};

export default ImageViewer;