import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import useRequest from '../../hooks/useRequest';
import { showToast, toastType } from '../../hooks/useToast';
import moment from 'moment';
import _ from 'lodash';

const Book = () => {
    const [party, setParty] = useState(null);
    const [participants, setParticipants] = useState([]);
    const [selectedParticipants, setSelectedParticipants] = useState([]);
    const [newParticipant, setNewParticipant] = useState({ name: '', dob: '', gender: '' });
    const { id } = useParams();
    const { makeRequest } = useRequest();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const fetchPartyDetails = useCallback(() => {
        makeRequest({
            url: `/api/party/${id}`,
            method: 'GET',
            onSuccess: ({ party }) => setParty(party),
            onFailure: (error) => showToast(error?.message || 'Failed to fetch party details', toastType.ERROR),
        });
    }, [id]);

    const fetchParticipants = useCallback(() => {
        makeRequest({
            url: '/api/user/participants',
            method: 'GET',
            onSuccess: ({ participants }) => setParticipants(participants),
            onFailure: (error) => showToast(error?.message || 'Failed to fetch participants', toastType.ERROR),
        });
    }, []);

    useEffect(() => {
        fetchPartyDetails();
        fetchParticipants();
    }, []);

    useEffect(() => {
        if (participants.length > 0 && selectedParticipants.length === 0) {
            setSelectedParticipants([participants[0].id]);
        }
    }, [participants]);

    const handleAddParticipant = () => {
        setParticipants([...participants, { ...newParticipant, id: Date.now() }]);
        setNewParticipant({ name: '', dob: '', gender: '' });
        setIsModalOpen(false);

        makeRequest({
            url: '/api/user/participants',
            method: 'POST',
            body: newParticipant,
            onSuccess: () => {
                showToast('Participant added successfully', toastType.SUCCESS);
                fetchParticipants();
            },
            onFailure: (error) => showToast(error?.message || 'Failed to add participant', toastType.ERROR),
        })

    };

    const handleRemoveParticipant = (participantId) => {
        setParticipants(participants.filter(participant => participant.id !== participantId));
        setSelectedParticipants(selectedParticipants.filter(id => id !== participantId));

        makeRequest({
            url: `/api/user/participants/${participantId}`,
            method: 'DELETE',
            onSuccess: () => {
                showToast('Participant removed successfully', toastType.SUCCESS);
                fetchParticipants();
            },
            onFailure: (error) => showToast(error?.message || 'Failed to remove participant', toastType.ERROR),
        })
    }

    const handleCheckboxChange = (participantId) => {
        setSelectedParticipants((prevSelected) =>
            prevSelected.includes(participantId)
                ? prevSelected.filter((id) => id !== participantId)
                : [...prevSelected, participantId]
        );
    };

    const bookParty = () => {

        const selectedParticipantDetails = participants.filter(participant =>
            selectedParticipants.includes(participant.id)
        ).map(participant => {
            const age = moment().diff(moment(participant.dob), 'years');
            return { ...participant, age, dob: undefined };
        });

        makeRequest({
            url: `/api/party/${id}/book`,
            method: 'POST',
            body: {
                participants: selectedParticipantDetails,
            },
            onSuccess: () => {
                //TODO: Payment Gateway Integration
            },
            onFailure: (error) => {
                showToast(error?.message || 'Failed to create booking', toastType.ERROR);
            },
        });
    };

    if (!party) {
        return <LoadingSpinner />;
    }

    if (party && (moment(party.date).isBefore(moment()) || (moment(party.date).isSame(moment(), 'day') && party.time < moment().format('HH:mm')))) {
        return <div className="tw-flex tw-justify-center tw-items-center tw-text-white">
            <h2 className="tw-text-2xl tw-font-bold tw-mt-8 tw-text-center">Booking Closed</h2>
        </div>
    }

    const formattedDate = moment(party.date).format('DD MMM YYYY');
    const formattedTime = moment(party.time, 'HH:mm').format('hh:mm A');

    return (
        <div className="tw-bg-black tw-text-white tw-min-h-screen tw-py-8">
            <div className="tw-max-w-4xl tw-mx-auto">
                <div className='tw-flex tw-items-center tw-gap-2 tw-mb-2'>
                    <button onClick={() => window.history.back()} className="tw-text-white tw-text-2xl tw-flex tw-items-center">
                        <ion-icon name={"arrow-back-outline"} className="tw-mr-2" />
                    </button>
                    <h1 className="tw-text-3xl md:tw-text-3xl tw-font-semibold tw-text-left tw-mt-2 tw-mb-2">
                        Booking Summary
                    </h1>
                </div>

                <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-8">
                    <div className="tw-space-y-6">
                        <ParticipantsList
                            participants={participants}
                            selectedParticipants={selectedParticipants}
                            handleCheckboxChange={handleCheckboxChange}
                            newParticipant={newParticipant}
                            setNewParticipant={setNewParticipant}
                            handleAddParticipant={handleAddParticipant}
                            isModalOpen={isModalOpen}
                            setIsModalOpen={setIsModalOpen}
                            handleRemoveParticipant={handleRemoveParticipant}
                        />
                        <PartyDetails party={party} formattedDate={formattedDate} formattedTime={formattedTime} />
                    </div>
                    <div className="tw-space-y-6">
                        <EventDetails party={party} />
                        <TotalAmount party={party} selectedParticipants={selectedParticipants} participants={participants} bookParty={bookParty} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Book;

const PartyDetails = ({ party, formattedDate, formattedTime }) => (
    <div className="tw-bg-[#393737] tw-rounded-lg tw-p-6 tw-shadow-lg">
        <h2 className="tw-text-3xl tw-font-bold tw-mb-4 ">{party.name}</h2>
        <p className="tw-mb-2 tw-flex tw-items-center">
            <ion-icon name="calendar-outline" class="tw-mr-2 "></ion-icon> {formattedDate}
        </p>
        <p className="tw-mb-2 tw-flex tw-items-center">
            <ion-icon name="time-outline" class="tw-mr-2 "></ion-icon> {formattedTime}
        </p>
        <p className="tw-mb-2 tw-flex tw-items-center">
            <ion-icon name="location-outline" class="tw-mr-2 "></ion-icon> {party.location.venue}
        </p>
        <p className="tw-mb-4 tw-flex tw-items-center">
            <ion-icon name="navigate-outline" class="tw-mr-2 "></ion-icon> {party.location.address}, {party.location.city}
        </p>
    </div>
)


const EventDetails = ({ party }) => (
    <div className="tw-bg-[#393737] tw-rounded-lg tw-p-6 tw-shadow-lg tw-text-left">
        <h3 className="tw-text-2xl tw-font-bold tw-mb-4 tw-text-center ">Event Details</h3>
        <p className="tw-mb-2"><span className="tw-font-semibold">Type:</span> {party.types.join(', ')}</p>
        <p className="tw-mb-2"><span className="tw-font-semibold">Cuisine:</span> {party.cuisines.join(', ')}</p>
        <p className="tw-mb-2"><span className="tw-font-semibold">Activities:</span> {party.activities.join(', ')}</p>
        <p className="tw-mb-4"><span className="tw-font-semibold">Description:</span> {party?.description}</p>
        <p className="tw-font-semibold">Organized by: {party?.organizer?.name}</p>
    </div>
);

const ParticipantsList = ({ participants, selectedParticipants, handleCheckboxChange, newParticipant, setNewParticipant, handleAddParticipant, isModalOpen, setIsModalOpen, handleRemoveParticipant }) => {

    const handleSubmit = (e) => {
        e.preventDefault();
        handleAddParticipant();
        setIsModalOpen(false);
    }

    return (
        <div className="tw-bg-[#393737] tw-rounded-lg tw-p-6 tw-shadow-lg">
            <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
                <h3 className="tw-text-2xl tw-font-bold">Participants</h3>
                <button
                    onClick={() => setIsModalOpen(true)}
                    className="tw-bg-orange-500 tw-text-white tw-px-4 tw-py-2 tw-rounded hover:tw-bg-orange-600"
                >
                    Add Participant
                </button>
            </div>

            {participants.length === 0 ? (
                <p>No Participants</p>
            ) : (
                participants.map((participant, index) => (
                    <div key={participant.id} className="tw-mb-4 tw-flex tw-justify-between tw-items-center tw-bg-[#2a2a2a] tw-p-3 tw-rounded">
                        <div className='tw-text-left tw-flex tw-items-center'>
                            <input
                                type="checkbox"
                                checked={selectedParticipants.includes(participant.id)}
                                onChange={() => handleCheckboxChange(participant.id)}
                                className="tw-mr-2"
                            />
                            <span className="tw-font-semibold">{participant.name + " "}</span>
                            <span className="tw-text-sm tw-text-gray-400">({moment().diff(participant.dob, 'years')}, {_.startCase(participant.gender)})</span>
                        </div>
                        <div className='tw-flex tw-gap-2'>
                            {index > 0 && (
                                <button
                                    className="tw-text-red-500 hover:tw-text-red-700 tw-transition-colors"
                                    onClick={() => handleRemoveParticipant(participant.id)}
                                >
                                    <ion-icon name="trash-outline"></ion-icon>
                                </button>
                            )}
                        </div>
                    </div>
                ))
            )}

            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onSubmit={handleSubmit}>
                <div className="tw-p-6">
                    <h3 className="tw-text-2xl tw-font-bold tw-mb-4">Add New Participant</h3>
                    <form onSubmit={handleSubmit} className='tw-space-y-4'>
                        <input
                            type="text"
                            placeholder="Name"
                            value={newParticipant.name}
                            required
                            onChange={(e) => setNewParticipant({ ...newParticipant, name: e.target.value })}
                            className="tw-w-full tw-px-3 tw-py-2 tw-rounded tw-bg-[#2a2a2a] tw-text-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#fb880c] tw-appearance-none"
                        />
                        <input
                            id="dob"
                            type="date"
                            placeholder="Date of Birth"
                            max={new Date().toISOString().split('T')[0]}
                            value={newParticipant.dob ? new Date(newParticipant.dob).toISOString().split('T')[0] : ''}
                            onChange={(e) => setNewParticipant({ ...newParticipant, dob: e.target.value })}
                            className="tw-w-full tw-px-3 tw-py-2 tw-rounded tw-bg-[#2a2a2a] tw-text-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#fb880c] tw-appearance-none [&::-webkit-calendar-picker-indicator]:tw-filter [&::-webkit-calendar-picker-indicator]:tw-invert"
                        />
                        <select
                            id="gender"
                            value={newParticipant.gender}
                            required
                            onChange={(e) => setNewParticipant({ ...newParticipant, gender: e.target.value })}
                            className="tw-w-full tw-px-3 tw-py-2 tw-rounded tw-bg-[#2a2a2a] tw-text-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#fb880c] tw-appearance-none"
                        >
                            <option value="">Select Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </select>
                        <div className="tw-flex tw-justify-end tw-gap-2">
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="tw-bg-gray-500 tw-text-white tw-px-4 tw-py-2 tw-rounded hover:tw-bg-gray-600"
                            >
                                Cancel
                            </button>
                            <button
                                type='submit'
                                className="tw-bg-orange-500 tw-text-white tw-px-4 tw-py-2 tw-rounded hover:tw-bg-orange-600"
                            >
                                Add
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
            <style>{`

                /* Custom checkbox styles */
                input[type="checkbox"] {
                    -webkit-appearance: none;
                    appearance: none;
                    width: 18px;
                    height: 18px;
                    border: 2px solid #fb880c;
                    border-radius: 4px;
                    outline: none;
                    cursor: pointer;
                    position: relative;
                }

                input[type="checkbox"]:checked {
                    background-color: #fb880c;
                }

                input[type="checkbox"]:checked::after {
                    content: '';
                    position: absolute;
                    left: 4px;
                    top: 1px;
                    width: 6px;
                    height: 12px;
                    border: solid black;
                    border-width: 0 3px 3px 0;
                    transform: rotate(45deg);
                }
            `}</style>
        </div>
    );
};

const TotalAmount = ({ party, selectedParticipants, participants, bookParty }) => (
    <div className="tw-bg-[#393737] tw-rounded-lg tw-p-6 tw-shadow-lg">
        <div className='tw-flex tw-justify-between tw-items-center'>
            <h3 className="tw-text-2xl tw-font-bold">Total Amount</h3>
            <p className="tw-text-2xl tw-font-semibold tw-text-center">₹{party.fees * selectedParticipants.length}</p>
        </div>

        {selectedParticipants?.length > 0 && (
            <div className="tw-flex tw-justify-center tw-mt-6">
                <button
                    onClick={bookParty}
                    className="tw-bg-orange-500 tw-text-white tw-py-3 tw-px-8 tw-rounded-full tw-font-semibold tw-text-lg hover:tw-bg-orange-600 tw-transition-colors tw-shadow-lg tw-flex tw-items-center"
                >
                    <ion-icon name="wallet-outline" class="tw-mr-2"></ion-icon>
                    Proceed to Pay
                </button>
            </div>
        )}
    </div>
);

const LoadingSpinner = () => (
    <div className="tw-flex tw-justify-center tw-items-center tw-h-screen">
        <div className="tw-w-16 tw-h-16 tw-border-t-4 tw-border-b-4 tw-border-gray-400 tw-rounded-full tw-animate-spin"></div>
    </div>
);

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-50">
            <div className="tw-bg-[#393737] tw-rounded-lg tw-shadow-lg tw-max-w-md tw-w-full tw-m-4">
                <div className="tw-relative">
                    <button
                        onClick={onClose}
                        className="tw-absolute tw-top-4 tw-right-4 tw-text-gray-400 hover:tw-text-white"
                    >
                        <ion-icon name="close-outline" class="tw-text-2xl"></ion-icon>
                    </button>
                    {children}
                </div>
            </div>
        </div>
    );
};