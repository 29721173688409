import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom';

export default () => {
    const navigate = useNavigate()
    const location = useLocation()

    const makeRequest = async ({ url, method, body = null, headers = {}, onSuccess, onFailure }) => {
        method = method.toUpperCase()
        try {
            const config = {
                method,
                url,
                data: body,
                headers: {
                    ...headers,
                    "x-access-token": localStorage.getItem('token')
                }
            }

            const response = await axios(config);

            if (onSuccess && typeof onSuccess === 'function') {
                onSuccess(response.data);
            }
        } catch (error) {
            console.log(error)
            if (error.response) {
                if (error.response.status === 400) {
                    onFailure && onFailure(error?.response?.data);
                } else if (error.request.status >= 500) {
                    onFailure && onFailure(error.response?.data ?? 'Network Error');
                } else if (error?.response?.status === 401) {
                    localStorage.clear()
                    return navigate({ pathname: "/", state: { from: location } })
                }
            }
        }
    }
    return { makeRequest }
}