import React, { useState, useEffect } from 'react';
import useRequest from '../../hooks/useRequest';
import { useSearchParams } from 'react-router-dom';
import { showToast, toastType } from '../../hooks/useToast';

const ForgotPasswordPage = () => {
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('')
    const [emailError, setEmailError] = useState('');
    const [otpField, setOtpField] = useState(false)
    const [otpError, setOtpError] = useState('')
    const [token, setToken] = useState()
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [passwordError, setPasswordError] = useState('')

    const [searchParams] = useSearchParams()

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const emailFromQuery = searchParams.get('email');
        if (emailFromQuery) {
            setEmail(emailFromQuery);
        }
    }, [searchParams]);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const { makeRequest } = useRequest()

    const handleSubmit = (e) => {
        e.preventDefault();
        setEmailError('')
        if (!validateEmail(email)) {
            return setEmailError('Enter valid email');
        }

        const role = searchParams.get('role')
        if (!role) {
            return setEmailError('Enter a valid role');
        }

        setIsLoading(true)
        makeRequest({
            url: '/api/auth/forgot-password',
            method: 'POST',
            body: { email, role },
            onSuccess: ({ token }) => {
                setIsLoading(false)
                setToken(token)
                setOtpField(true)
            },
            onFailure: (error) => {
                setIsLoading(false)
                showToast(error.message, toastType.ERROR)
            }
        })
    };

    const verifyOtp = () => {
        setIsLoading(true)
        makeRequest({
            url: '/api/auth/verify-otp',
            method: 'POST',
            body: { otp, token },
            onSuccess: () => {
                setIsLoading(false)
                setOtpField(false)
                setPasswordError('')
            },
            onFailure: (error) => {
                setIsLoading(false)
                setOtpError(showToast(error.message, toastType.ERROR))
            }
        })
    }

    const handlePasswordChange = () => {

        if (password.length < 8) {
            return setPasswordError('Password must be at least 8 characters');
        }

        if (password !== confirmPassword) {
            return setPasswordError('Passwords do not match');
        }

        makeRequest({
            url: '/api/auth/change-password',
            method: 'POST',
            body: { password, token },
            onSuccess: () => {
                window.location.assign('/login?role=guest')
            },
            onFailure: (error) => {
                setIsLoading(false)
                showToast(error.message, toastType.ERROR)
            }
        })
    }

    const handleBackClick = () => {
        window.history.back();
    };

    return (
        <div className="tw-bg-black tw-flex tw-flex-col tw-justify-center tw-items-center tw-px-4 sm:tw-px-6 lg:tw-px-8">
            <div className="tw-mt-2 tw-flex tw-items-center tw-w-full tw-justify-center tw-relative ">
                <button onClick={handleBackClick} className="tw-text-white tw-text-2xl tw-absolute tw-left-4">
                    <ion-icon name={"arrow-back-outline"}  />
                </button>
                <img
                    src="/assets/images/party-connect-hub.svg"
                    alt="PartyConnectHub Logo"
                    width="100"
                />
            </div>
            <div className="tw-w-full tw-max-w-xs">
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/765ef6ba9309e06eaa38db9ca7f6785fa758cdadc0b4575d496e2fac9132a7b8?"
                    alt="Party Connect Hub"
                    className="tw-w-full tw-object-scale-down"
                    width="300"
                    height="200"
                />
            </div>

            <h1 className="tw-text-white tw-text-center tw-text-3xl tw-font-bold tw-mb-2">
                PartyConnectHub
            </h1>

            {!otpField && !token && (
                <p className="tw-text-gray-300 tw-text-center tw-mb-6">
                    Enter your email to reset your password.
                </p>
            )}
            {otpField && (
                <p className="tw-text-gray-300 tw-text-center tw-mb-6">
                    Enter the OTP sent to your email.
                </p>
            )}
            {!otpField && token && (
                <p className="tw-text-gray-300 tw-text-center tw-mb-6">
                    Enter your new password.
                </p>
            )}

            <form onSubmit={handleSubmit} className="tw-w-full tw-max-w-sm tw-flex tw-flex-col tw-gap-4">
                {!otpField && !token && (
                    <div>
                        <input
                            style={{ backgroundColor: '#393737' }}
                            type="email"
                            name="email"
                            placeholder="Enter your email"
                            className={`tw-w-full tw-py-3 tw-px-4 tw-rounded-full tw-bg-gray-700 tw-text-gray-300 tw-border ${emailError ? 'tw-border-red-500' : 'tw-border-none'} focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#fb880c] focus:${emailError ? 'tw-border-red-500' : ''}`}
                            value={email}
                            onChange={(e) => { setEmailError(''); setEmail(e.target.value) }}
                        />
                        {emailError && <p className="tw-text-red-500 tw-text-sm tw-mt-2">* {emailError}</p>}
                    </div>
                )}

                {otpField && (
                    <div>
                        <input
                            style={{ backgroundColor: '#393737' }}
                            type="number"
                            name="otp"
                            placeholder="Enter OTP"
                            className={`tw-w-full tw-py-3 tw-px-4 tw-rounded-full tw-bg-gray-700 tw-text-gray-300 tw-border ${otpError ? 'tw-border-red-500' : 'tw-border-none'} focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#fb880c] focus:${otpError ? 'tw-border-red-500' : ''}`}
                            value={otp}
                            onChange={(e) => { setOtpError(''); setOtp(e.target.value) }}
                        />
                        {otpError && <p className="tw-text-red-500 tw-text-sm tw-mt-2">* {otpError}</p>}
                    </div>
                )}

                {!otpField && token && (
                    <>
                        <div>
                            <input
                                style={{ backgroundColor: '#393737' }}
                                type="password"
                                name="password"
                                placeholder="New Password"
                                className={`tw-w-full tw-py-3 tw-px-4 tw-rounded-full tw-bg-gray-700 tw-text-gray-300 tw-border ${passwordError ? 'tw-border-red-500' : 'tw-border-none'} focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#fb880c] focus:${passwordError ? 'tw-border-red-500' : ''}`}
                                value={password}
                                onChange={(e) => { setPasswordError(''); setPassword(e.target.value); }}
                            />
                        </div>
                        <div>
                            <input
                                style={{ backgroundColor: '#393737' }}
                                type="password"
                                name="confirmPassword"
                                placeholder="Confirm Password"
                                className={`tw-w-full tw-py-3 tw-px-4 tw-rounded-full tw-bg-gray-700 tw-text-gray-300 tw-border ${passwordError ? 'tw-border-red-500' : 'tw-border-none'} focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#fb880c] focus:${passwordError ? 'tw-border-red-500' : ''}`}
                                value={confirmPassword}
                                onChange={(e) => { setPasswordError(''); setConfirmPassword(e.target.value); }}
                            />
                        </div>
                        {passwordError && <p className="tw-text-red-500 tw-text-sm tw-mt-2">* {passwordError}</p>}
                    </>
                )}

                {!otpField && !token && (
                    <button
                        type="submit"
                        className="tw-w-full tw-bg-orange-500 tw-text-white tw-py-3 tw-px-4 tw-rounded-full tw-font-semibold tw-text-lg tw-mt-2 hover:tw-bg-orange-600"
                        disabled={isLoading}
                    >
                        {isLoading ? 'Sending OTP...' : 'Send OTP'}
                    </button>
                )}
            </form>

            {otpField && (
                <button
                    onClick={verifyOtp}
                    className="tw-w-full tw-max-w-sm tw-bg-orange-500 tw-text-white tw-py-3 tw-rounded-full tw-font-semibold tw-text-lg tw-mt-4 hover:tw-bg-orange-600"
                    disabled={isLoading}
                >
                    {isLoading ? 'Verifying OTP...' : 'Verify OTP'}
                </button>
            )}

            {!otpField && token && (
                <button
                    onClick={handlePasswordChange}
                    className="tw-w-full tw-max-w-sm tw-bg-orange-500 tw-text-white tw-py-3 tw-rounded-full tw-font-semibold tw-text-lg tw-mt-4 hover:tw-bg-orange-600"
                    disabled={isLoading}
                >
                    {isLoading ? 'Changing Password...' : 'Change Password'}
                </button>
            )}

            <div className="tw-text-center tw-text-white tw-mt-6">
                Remember your password? <a href="/login" className="tw-font-semibold tw-text-white hover:tw-underline">Login</a>
            </div>
        </div>
    );
};

export default ForgotPasswordPage;
