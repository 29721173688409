import React from 'react';

const UserList = ({ title, users, emptyMessage, actionButton, onActionClick }) => {
    return (
        <div className="tw-min-h-screen tw-bg-[#161616] tw-text-white tw-p-4 md:tw-p-8">
            <div className="tw-max-w-4xl tw-mx-auto">
                <div className='tw-flex tw-items-center tw-gap-2 tw-mb-2'>
                    <button onClick={() => window.history.back()} className="tw-text-white tw-text-2xl tw-flex tw-items-center">
                        <ion-icon name={"arrow-back-outline"} className="tw-mr-2" />
                    </button>
                    <h3 className="tw-text-xl md:tw-text-2xl tw-font-semibold tw-text-left tw-mt-2 tw-mb-2">
                        {title}
                    </h3>
                </div>

                {users.length === 0 ? (
                    <p className="tw-text-center tw-text-gray-400">{emptyMessage}</p>
                ) : (
                    <ul className="tw-space-y-4">
                        {users.map((user, index) => (
                            <li key={index} className="tw-bg-[#2c2c2c] tw-rounded-lg tw-p-6 tw-flex tw-items-center tw-justify-between">
                                <div className='tw-flex tw-items-center tw-gap-2'>
                                    {user.profile ?
                                        <img
                                            src={user.profile}
                                            alt=""
                                            className="tw-w-14 tw-h-14 tw-rounded-full tw-mr-4 tw-object-cover"
                                        /> :
                                        <div className="tw-w-14 tw-h-14 tw-rounded-full tw-mr-4 tw-bg-gray-400 tw-flex tw-items-center tw-justify-center">
                                            <span className="tw-text-2xl tw-text-gray-600">
                                                {user.name.charAt(0)}
                                            </span>
                                        </div>
                                    }
                                    <div>
                                        <h4 className="tw-text-lg tw-font-semibold">{user.name}</h4>
                                        {user.email && <p className="tw-text-sm tw-text-gray-400">{user.email}</p>}
                                    </div>
                                </div>
                                <div>
                                    <button onClick={() => onActionClick(user.id)} className='tw-text-white'>
                                        {actionButton}
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default UserList;