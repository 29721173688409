import React, { useState } from 'react'
import ReCAPTCHA from "react-google-recaptcha"
import useRequest from '../../hooks/useRequest'
import { showToast, toastType } from '../../hooks/useToast'
import { SITE_KEY } from '../../constants/reCaptcha'

const ContactUs = () => {
  const [captchaValue, setCaptchaValue] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const { makeRequest } = useRequest()

  const handleSubmit = (e) => {
    e.preventDefault()
    const {name, email, message} = e.target

    if (!name.value || !email.value || !message.value) {
      alert("Please fill in all fields")
      return
    }

    if (!captchaValue) {
      alert("Please verify that you are not a robot")
      return
    }

    setIsLoading(true)
    makeRequest({
      url: "/api/user/contact-us",
      method: "POST",
      body: {
        name: e.target.name.value,
        email: e.target.email.value,
        message: e.target.message.value,
      },
      onSuccess: () => {
        showToast("Message sent successfully", toastType.SUCCESS)
        window.history.back()
      },
      onError: (error) => {
        setIsLoading(false)
        showToast(error.message, toastType.ERROR)
      }
    })
  }

  return (
    <div className="tw-bg-[#121212] tw-text-white tw-m-4 tw-rounded-md">
    <div className="tw-mt-2 tw-mb-4 tw-flex tw-items-center tw-w-full tw-justify-center tw-relative ">
        <button onClick={() => window.history.back()} className="tw-text-white tw-text-2xl tw-absolute tw-left-4">
            <ion-icon name={"arrow-back-outline"}  />
        </button>
        <img
            src="/assets/images/party-connect-hub.svg"
            alt="PartyConnectHub Logo"
            width="100"
        />
    </div>
        <div className="tw-max-w-3xl tw-mx-auto tw-bg-[#1e1e1e] tw-shadow-lg tw-rounded-md tw-px-6 tw-py-8 tw-text-left">
            <h1 className="tw-text-3xl tw-font-bold tw-mb-4">Contact Us</h1>
            <p className="tw-text-base tw-text-gray-400 tw-mb-6">If you have any questions or feedback, please feel free to contact us.</p>
            
            <form onSubmit={handleSubmit} className="tw-flex tw-flex-col tw-gap-4">
              <div className="tw-flex tw-flex-col tw-gap-2">
                <label className="tw-text-sm tw-font-semibold">Name</label>
                <input name="name" placeholder="John Doe" required={true} type="text" className="tw-bg-[#2e2e2e] tw-rounded-md tw-px-4 tw-py-2 tw-text-white" />
              </div>

              <div className="tw-flex tw-flex-col tw-gap-2">
                <label className="tw-text-sm tw-font-semibold">Email</label>
                <input name="email" placeholder="example@example.com" required={true} type="email" className="tw-bg-[#2e2e2e] tw-rounded-md tw-px-4 tw-py-2 tw-text-white" />
              </div>

              <div className="tw-flex tw-flex-col tw-gap-2">
                <label className="tw-text-sm tw-font-semibold">Message</label>
                <textarea name="message" placeholder="Your message here..." required={true} rows={5} className="tw-bg-[#2e2e2e] tw-rounded-md tw-px-4 tw-py-2 tw-text-white" />
              </div>

              <div className="tw-flex tw-flex-col tw-gap-4">
                <ReCAPTCHA
                  sitekey={SITE_KEY}
                  onChange={setCaptchaValue}
                  theme="dark"
                  className="tw-flex tw-justify-center"
                />
              </div>

              <div className="tw-flex tw-justify-end">
                <button 
                  type="submit" 
                  className="tw-bg-orange-500 tw-text-white tw-rounded-md tw-px-4 tw-py-2 tw-font-semibold"
                  disabled={isLoading}
                >
                  {isLoading ? "Sending..." : "Send Message"}
                </button>
              </div>
            </form>
        </div>
    </div>
  )
}

export default ContactUs