import { useState, useEffect } from 'react';
import useRequest from '../../hooks/useRequest';
import { showToast, toastType } from '../../hooks/useToast';

const WalletRequest = () => {
    
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const { makeRequest } = useRequest();

  useEffect(() => {
    fetchWalletRequests();
  }, []);

  const fetchWalletRequests = () => {
    makeRequest({
      url: '/api/user/wallet/requests',
      method: 'GET',
      onSuccess: (data) => {
        setRequests(data.requests);
        setLoading(false);
      },
      onFailure: (error) => {
        showToast(error?.message || 'Failed to fetch wallet requests', toastType.ERROR);
        setLoading(false);
      },
    });
  };

  if (loading) {
    return (
      <div className="tw-flex tw-justify-center tw-items-center tw-h-screen">
        <div className="tw-w-16 tw-h-16 tw-border-t-4 tw-border-b-4 tw-border-gray-400 tw-rounded-full tw-animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="tw-min-h-screen tw-bg-[#161616] tw-text-white tw-p-4 md:tw-p-8">
      <div className="tw-max-w-4xl tw-mx-auto">
        <h3 className="tw-text-xl md:tw-text-2xl tw-font-semibold tw-text-left tw-mt-4 tw-mb-8">
          Wallet Requests
        </h3>
        {requests.length === 0 ? (
          <p className="tw-text-center tw-text-gray-400">No wallet requests found.</p>
        ) : (
          <ul className="tw-space-y-4">
            {requests.map((request) => (
              <li key={request.id} className="tw-bg-[#2c2c2c] tw-rounded-lg tw-p-6">
                <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
                  <span className="tw-text-lg tw-font-semibold">₹{request.amount.toFixed(2)}</span>
                  <span className={`tw-text-sm tw-font-medium tw-px-2 tw-py-1 tw-rounded-full ${
                    request.status === 'pending' ? 'tw-bg-yellow-500 tw-text-black' :
                    request.status === 'completed' ? 'tw-bg-green-500 tw-text-white' :
                    'tw-bg-red-500 tw-text-white'
                  }`}>
                    {request.status.charAt(0).toUpperCase() + request.status.slice(1)}
                  </span>
                </div>
                <p className="tw-text-sm tw-text-gray-400 tw-text-left">Date: {new Date(request.date).toLocaleDateString()}</p>
                <p className="tw-text-sm tw-text-gray-400 tw-text-left">Bank Name: {request.bank}</p>
                <p className="tw-text-sm tw-text-gray-400 tw-text-left">IFSC: {request.ifsc}</p>
                <p className="tw-text-sm tw-text-gray-400 tw-text-left">Account Number: {request.account}</p>
                <p className="tw-text-sm tw-text-gray-400 tw-text-left">Account Holder Name: {request.holder}</p>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default WalletRequest;
