import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useRequest from '../../hooks/useRequest';
import { showToast, toastType } from '../../hooks/useToast';

const EditProfile = () => {
    const [user, setUser] = useState(null);
    const [name, setName] = useState('');
    const [bio, setBio] = useState('');
    const [photo, setPhoto] = useState(null);
    const [phone, setPhone] = useState('');
    const [gender, setGender] = useState('');
    const [dob, setDob] = useState();
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { makeRequest } = useRequest();
    const navigate = useNavigate();

    useEffect(() => {
        makeRequest({
            url: '/api/user',
            method: 'GET',
            onSuccess: ({ user }) => {
                setUser(user);
                setName(user.name);
                setBio(user.bio);
                setPhone(user.phone);
                setGender(user.gender);
                setDob(user.dob ? new Date(user.dob).toISOString().split('T')[0] : '');
            },
            onFailure: (error) => {
                showToast('Failed to load user profile', toastType.ERROR);
            },
            finally: () => {
                setIsSubmitting(false);
            }
        });
    }, []);

    useEffect(() => {
        setErrors({});
    }, [name, bio, photo, phone, gender, dob]);

    const validateForm = () => {
        let isValid = true;
        let newErrors = {};

        if (!name.trim()) {
            newErrors.name = "Name is required";
            isValid = false;
        } else if (name.length > 56 || name.length < 2) {
            newErrors.name = "Name should be between 2 and 56 characters";
            isValid = false;
        }

        if (bio?.length > 256) {
            newErrors.bio = "Bio should not exceed 256 characters";
            isValid = false;
        }

        if (phone && !/^\d{10}$/.test(phone)) {
            newErrors.phone = "Phone number should be 10 digits";
            isValid = false;
        }

        if (gender && !['male', 'female', 'other'].includes(gender.toLowerCase())) {
            newErrors.gender = "Gender should be Male, Female, or Other";
            isValid = false;
        }

        if (photo && photo.size > 5 * 1024 * 1024) {
            newErrors.photo = "Photo size should not exceed 5MB";
            isValid = false;
        }

        if (dob && !isValidDate(dob)) {
            newErrors.dob = "Please enter a valid date of birth";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) {
            showToast('Please correct the errors in the form', toastType.ERROR);
            return;
        }

        setIsSubmitting(true);

        const formData = new FormData();
        formData.append('name', name);
        formData.append('bio', bio || '');
        formData.append('phone', phone || '');
        formData.append('gender', gender || '');
        formData.append('initialPhoto', user.profile || '');
        formData.append('dob', dob || '');

        if (photo instanceof File) {
            formData.append('photo', photo);
        }

        makeRequest({
            url: '/api/user/edit',
            method: 'PUT',
            body: formData,
            onSuccess: () => {
                showToast('Profile updated successfully', toastType.SUCCESS);
                setTimeout(() => {
                    navigate('/profile');
                }, 1500);
            },
            onFailure: (error) => {
                setIsSubmitting(false);
                showToast(error?.message || 'Failed to update profile', toastType.ERROR);
            }
        });
    };

    const handlePhoneChange = (e) => {
        const value = e.target.value.replace(/\D/g, '').slice(0, 10);
        setPhone(value);

        if (!/^\d{10}$/.test(value)) {
            setErrors((prev) => ({ ...prev, phone: "Phone number should be 10 digits" }));
        } else {
            setErrors((prev) => ({ ...prev, phone: null }));
        }
    };

    const isValidDate = (dateString) => {
        const regex = /^\d{4}-\d{2}-\d{2}$/;
        if (!regex.test(dateString)) return false;
        const date = new Date(dateString);
        return !isNaN(date.getTime()) && date <= new Date();
    };

    if (!user) {
        return (
            <div className="tw-flex tw-justify-center tw-items-center tw-h-screen tw-bg-[#161616]">
                <div className="tw-loader tw-ease-linear tw-rounded-full tw-border-4 tw-border-t-4 tw-border-gray-200 tw-h-12 tw-w-12 tw-spin"></div>
            </div>
        );
    }

    const handleBackClick = () => {
        window.history.back();
    };

    return (
        <div className="tw-min-h-screen tw-bg-[#161616] tw-text-white tw-p-4 md:tw-p-8">
            <div className="tw-max-w-4xl tw-mx-auto">
                <div className=" tw-flex tw-justify-start tw-items-center tw-top-4 tw-left-4 tw-mb-6">
                    <button onClick={handleBackClick} className="tw-text-white tw-text-2xl tw-flex tw-items-center tw-mr-4">
                        <ion-icon name={"arrow-back-outline"} className="tw-mr-2" />
                    </button>
                    <h3 className="tw-text-xl md:tw-text-2xl tw-font-semibold tw-text-left">Edit Profile</h3>
                </div>

                <div className="tw-mx-auto tw-w-32 tw-h-32 tw-rounded-full tw-bg-gray-700 tw-flex tw-items-center tw-justify-center tw-mb-4">
                    {user.profile ? (
                        <img
                            src={user.profile}
                            alt=""
                            className="tw-w-full tw-h-full tw-object-cover tw-rounded-full tw-border tw-border-orange-500"
                        />
                    ) : (
                        <span className="tw-text-4xl tw-text-white tw-font-bold">{getInitials(user.name)}</span>
                    )}
                </div>

                <form onSubmit={handleSubmit} className="tw-space-y-6">
                    <div>
                        <label className="tw-block tw-text-lg md:tw-text-lg tw-mb-2 tw-text-left">Photo</label>
                        <div className="tw-flex tw-items-center tw-space-x-4">
                            <input
                                type="file"
                                onChange={(e) => setPhoto(e.target.files[0])}
                                accept=".jpg,.jpeg,.png"
                                className="tw-text-sm md:tw-text-base"
                            />
                        </div>
                        {errors.photo && <p className="tw-text-red-500 tw-mt-1 tw-text-sm">{errors.photo}</p>}
                    </div>

                    <div>
                        <label htmlFor="name" className="tw-block tw-text-lg md:tw-text-lg tw-mb-2 tw-text-left">Name</label>
                        <input
                            id="name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value.slice(0, 56))}
                            maxLength={56}
                            placeholder="Enter your name"
                            className="tw-w-full tw-bg-[#2c2c2c] tw-text-white tw-border-none tw-rounded-full tw-px-6 tw-py-3 tw-text-lg focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#fb880c]"
                            aria-invalid={errors.name ? "true" : "false"}
                            aria-describedby="name-error"
                        />
                        {errors.name && <p id="name-error" className="tw-text-red-500 tw-mt-1 tw-text-sm">{errors.name}</p>}
                    </div>

                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
                        <div>
                            <label htmlFor="email" className="tw-block tw-text-lg md:tw-text-lg tw-mb-2 tw-text-left">Email</label>
                            <input
                                id="email"
                                type="email"
                                value={user.email}
                                readOnly
                                placeholder="Enter your email"
                                className="tw-w-full tw-bg-[#2c2c2c] tw-text-white tw-border-none tw-rounded-full tw-px-6 tw-py-3 tw-text-lg focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#fb880c]"
                            />
                        </div>

                        <div>
                            <label htmlFor="phone" className="tw-block tw-text-lg md:tw-text-lg tw-mb-2 tw-text-left">Phone</label>
                            <input
                                id="phone"
                                type="tel"
                                value={phone}
                                onChange={handlePhoneChange}
                                maxLength={10}
                                placeholder="Enter your phone number"
                                className="tw-w-full tw-bg-[#2c2c2c] tw-text-white tw-border-none tw-rounded-full tw-px-6 tw-py-3 tw-text-lg focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#fb880c]"
                                aria-invalid={errors.phone ? "true" : "false"}
                                aria-describedby="phone-error"
                            />
                            {errors.phone && <p id="phone-error" className="tw-text-red-500 tw-mt-1 tw-text-sm">{errors.phone}</p>}
                        </div>
                    </div>

                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4 tw-mt-6">
                        <div>
                            <label htmlFor="gender" className="tw-block tw-text-lg md:tw-text-lg tw-mb-2 tw-text-left">Gender</label>
                            <select
                                id="gender"
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                                className="tw-w-full tw-bg-[#2c2c2c] tw-text-white tw-border-none tw-rounded-full tw-px-6 tw-py-3 tw-text-lg focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#fb880c] tw-appearance-none"
                            >
                                <option value="">Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                            </select>
                            {errors.gender && <p id="gender-error" className="tw-text-red-500 tw-mt-1 tw-text-sm">{errors.gender}</p>}
                        </div>

                        <div>
                            <label htmlFor="dob" className="tw-block tw-text-lg md:tw-text-lg tw-mb-2 tw-text-left">Date of Birth</label>
                            <input
                                id="dob"
                                type="date"
                                max={new Date().toISOString().split('T')[0]}
                                value={dob ? new Date(dob).toISOString().split('T')[0] : ''}
                                onChange={(e) => setDob(e.target.value)}
                                className="tw-w-full tw-bg-[#2c2c2c] tw-text-white tw-border-none tw-rounded-full tw-px-6 tw-py-3 tw-text-lg focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#fb880c] [&::-webkit-calendar-picker-indicator]:tw-filter [&::-webkit-calendar-picker-indicator]:tw-invert"
                                aria-invalid={errors.dob ? "true" : "false"}
                                aria-describedby="dob-error"
                            />
                            {errors.dob && <p id="dob-error" className="tw-text-red-500 tw-mt-1 tw-text-sm">{errors.dob}</p>}
                        </div>
                    </div>

                    <div>
                        <label htmlFor="bio" className="tw-block tw-text-lg md:tw-text-lg tw-mb-2 tw-text-left">Bio</label>
                        <textarea
                            id="bio"
                            value={bio}
                            onChange={(e) => setBio(e.target.value.slice(0, 256))}
                            maxLength={256}
                            className="tw-w-full tw-bg-[#2c2c2c] tw-text-white tw-border-none tw-rounded-lg tw-px-6 tw-py-3 tw-text-lg focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#fb880c]"
                            rows="3"
                        />
                        {errors.bio && <p className="tw-text-red-500 tw-mt-1 tw-text-sm">{errors.bio}</p>}
                        <p className="tw-text-gray-400 tw-text-sm tw-mt-1">{bio?.length || 0}/256 characters</p>
                    </div>

                    <div>

                        <button
                            type="submit"
                            className={`tw-w-full tw-mb-16 tw-bg-orange-500 hover:tw-bg-orange-600 tw-text-white tw-text-xl md:tw-text-2xl tw-py-3 tw-rounded-full tw-mt-2 ${isSubmitting && "tw-opacity-50 tw-pointer-events-none"}`}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? "Updating..." : "Update"}
                        </button>
                    </div>

                </form>
            </div>
        </div>
    );
};

export default EditProfile;

const getInitials = (name) => name ? name.split(' ').map(n => n[0]).join('').toUpperCase() : '';