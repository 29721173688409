import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const guestNavItems = [
  { path: '/home', icon: 'home' },
  { path: '/search', icon: 'search' },
  { path: '/notification', icon: 'notifications' },
  { path: '/profile', icon: 'person' },
];

const hostNavItems = [
  { path: '/home', icon: 'home' },
  { path: '/followers', icon: 'heart' },
  { path: '/party/create', icon: 'add-circle' },
  { path: '/wallet', icon: 'wallet' },
  { path: '/profile', icon: 'person' },
];

const NavBar = () => {
  const [isVisible, setIsVisible] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const { role } = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : { role: 'guest' }

  let lastScrollY = window.scrollY;

  const handleScroll = () => {
    if (window.scrollY > lastScrollY) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
    lastScrollY = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setIsVisible(true);
  }, [location.pathname]);

  const handleNavigation = useCallback((path) => {
    navigate(path);
    setIsVisible(true);
  }, []);

  const navItems = useMemo(() => {
    return role === 'host' ? hostNavItems : guestNavItems;
  }, [role]);

  return (
    <div className={`tw-fixed tw-bottom-0 tw-left-0 tw-w-full tw-bg-[#393737] tw-text-white tw-flex tw-justify-around tw-items-center tw-py-3 tw-transition-transform tw-duration-300 tw-ease-in-out ${isVisible ? 'tw-translate-y-0' : 'tw-translate-y-full'}`}>
      {navItems.map(({ path, icon }) => (
        <div key={path} className="tw-text-center tw-cursor-pointer" onClick={() => handleNavigation(path)}>
          <ion-icon
            name={icon}
            class={`tw-text-3xl ${icon === 'add-circle' ? 'tw-text-orange-500' : ''}`}
          />
        </div>
      ))}
    </div>
  );
};

export default NavBar;