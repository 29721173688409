import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { enivornment } from '../../helpers/environment';
import { formatDate, formatTime } from '../../helpers/time';

const PartyCard = ({ party, onDelete, openinNewTab=false }) => {
    const isHost = localStorage.getItem('role') === 'host';
    const navigate = useNavigate();

    const handleDelete = (e) => {
        e.stopPropagation();
        onDelete(party.id);
    };

    const handleEdit = (e) => {
        e.stopPropagation();
        navigate(`/party/${party.id}/edit`);
    };

    const handleCardClick = () => {
        if (openinNewTab) {
            window.open(`/party/${party.id}`, '_blank');
        } else {
            navigate(`/party/${party.id}`);
        }
    };

    return (
        <div
            onClick={handleCardClick}
            key={party.id}
            className="tw-bg-[#393737] tw-rounded-xl tw-overflow-hidden tw-shadow-lg tw-flex tw-flex-col 
                       tw-transition-all tw-duration-300 hover:tw-shadow-2xl hover:tw-scale-105 tw-cursor-pointer tw-border-2 tw-border-[#393737] tw-border-solid"
        >
            <div className="tw-relative tw-pb-[56.25%] tw-overflow-hidden">
                <img
                    src={enivornment === 'LIVE' ? `https://partyconnecthub.com${party.coverImage}` : `http://localhost:5000${party.coverImage}`}
                    alt={party.name}
                    className="tw-absolute tw-h-full tw-w-full tw-object-cover 
                    tw-transition-transform tw-duration-300 hover:tw-scale-110"
                />
                <div className="tw-absolute tw-top-2 tw-left-2 tw-bg-[#2C2C2C] tw-text-orange-300 tw-px-2 tw-py-1 tw-rounded tw-text-xs tw-font-semibold">
                    {party?.types[0]}
                </div>
                {isHost && (
                    <>
                        <button onClick={handleEdit} className="tw-absolute tw-top-2 tw-right-20 tw-bg-blue-500 tw-text-white tw-px-3 tw-py-1 tw-rounded tw-text-xs tw-font-semibold hover:tw-bg-blue-600 tw-transition-colors tw-duration-300">
                            Edit
                        </button>
                        <button
                            className="tw-absolute tw-top-2 tw-right-2 tw-bg-red-500 tw-text-white tw-px-2 tw-py-1 tw-rounded tw-text-xs tw-font-semibold tw-cursor-pointer hover:tw-bg-red-600 tw-transition-colors tw-duration-300"
                            onClick={handleDelete}
                        >
                            Delete
                        </button>
                    </>
                )}
            </div>
            <div className="tw-p-4 tw-flex tw-flex-col tw-flex-grow">
                <h2 className="tw-text-white tw-font-bold tw-text-lg tw-mb-1 tw-text-left hover:tw-text-orange-300 tw-transition-colors tw-duration-300">{party.name}</h2>
                <div className='tw-flex tw-justify-between tw-items-center tw-mb-3' >
                    <div className='tw-items-center'>
                        {party?.organizer?.name.length > 0 && <p className="tw-text-gray-400 tw-text-sm tw-text-left">{party?.organizer?.name},</p>}
                        <p className='tw-text-gray-400 tw-text-sm tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis tw-max-w-[152px]'>
                            {party.location.venue}, {party.location.address}, {party.location.city}
                        </p>
                    </div>
                    {!isHost &&
                        <Link 
                            to={`/book/${party.id}`} 
                            className="tw-bg-orange-500 tw-text-white tw-px-3 tw-py-1 tw-rounded-full tw-text-base tw-font-semibold hover:tw-bg-orange-600"
                            onClick={(e) => e.stopPropagation()}
                        >
                            Book Now
                        </Link>
                    }
                </div>
                <div className="tw-flex tw-justify-between tw-text-sm tw-text-gray-300">
                    <div className="tw-text-center tw-flex-1">
                        <p className="tw-font-bold">{formatDate(party.date)}</p>
                        <p>Date</p>
                    </div>
                    <div className="tw-h-10 tw-w-px tw-bg-gray-600 tw-mx-2"></div>
                    <div className="tw-text-center tw-flex-1">
                        <p className="tw-font-bold">{formatTime(party.time)}</p>
                        <p>Time</p>
                    </div>
                    <div className="tw-h-10 tw-w-px tw-bg-gray-600 tw-mx-2"></div>
                    <div className="tw-text-center tw-flex-1">
                        <p className="tw-font-bold">₹{party.fees}</p>
                        <p>Price</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PartyCard;