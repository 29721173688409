import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="tw-sticky tw-top-0 tw-left-0 tw-right-0 tw-bg-black tw-z-10 tw-py-2 tw-px-2 tw-flex tw-justify-center tw-items-center tw-w-full">
      <Link to="/home" className="tw-w-40 tw-h-12">
        <img
          src={'/assets/images/party-connect-hub.svg'}
          alt="Party Connect Hub"
          className="tw-w-full tw-h-full tw-object-contain"
        />
      </Link>
    </header>
  );
};

export default Header;