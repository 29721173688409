import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Login from './pages/Login';
import ForgotPassword from './pages/Login/forgot-password';
import Register from './pages/Login/register';
import Profile from './pages/Profile';
import EditProfile from './pages/Profile/edit';
import Home from './pages/Home/home';
import MainLayout from './components/Layout';
import { Toaster } from 'react-hot-toast';
import Notification from './pages/Notification';
import Party from './pages/Party';
import Book from './pages/Party/book';
import Search from './pages/Search';
import CreateParty from './pages/Party/create';
import Wallet from './pages/Wallet';
import WalletRequest from './pages/Wallet/request';
import Followers from './pages/User/followers.js';
import Following from './pages/User/following.js';
import HostParties from './pages/User/parties.js';
import Bookings from './pages/User/bookings';
import BookingDetails from './pages/User/booking-details';
import HostBookings from './pages/User/host-bookings';
import HostBookingDetails from './pages/User/host-booking-details';
import TermsAndCondition from './pages/Other/terms-and-condition';
import PrivacyPolicy from './pages/Other/privacy-policy';
import ContactUs from './pages/Other/contact-us';

function App() {
  return (
    <div className="App">
      <Toaster />
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path='/terms-and-conditions' element={<TermsAndCondition />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/contact-us' element={<ContactUs />} />

          <Route element={<MainLayout />}>
            <Route path="/profile" element={<Profile />} />
            <Route path="/profile/edit" element={<EditProfile />} />
            <Route path="/home" element={<Home />} />
            <Route path="/notification" element={<Notification />} />
            <Route path="/party/:id" element={<Party />} />
            <Route path="/book/:id" element={<Book />} />
            <Route path="/search" element={<Search />} />
            <Route path="/party/create" element={<CreateParty />} />
            <Route path="/party/:id/edit" element={<CreateParty />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/wallet/requests" element={<WalletRequest />} />
            <Route path='/followers' element={<Followers />} />
            <Route path='/following' element={<Following />} />
            <Route path='/organizer/:id/parties' element={<HostParties />} />
            <Route path='/bookings' element={<Bookings />} />
            <Route path='/booking/:id' element={<BookingDetails />} />
            <Route path='/host/bookings/parties' element={<HostBookings />} />
            <Route path='/host/booking/party/:partyId' element={<HostBookingDetails />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;