import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useRequest from '../../hooks/useRequest'
import { showToast, toastType } from '../../hooks/useToast'
import moment from 'moment'
import _ from 'lodash'

const BookingDetails = () => {

    const { id } = useParams()
    const [booking, setBooking] = useState(null)

    const { makeRequest } = useRequest()

    useEffect(() => {
        getBookingDetails()
    }, [])

    const getBookingDetails = () => {
        makeRequest({
            url: `/api/user/bookings/${id}`,
            method: 'GET',
            onSuccess: ({ booking }) => {
                setBooking(booking)
            },
            onFailure: (error) => {
                showToast(error?.message || 'Failed to fetch booking details', toastType.ERROR)
            }
        })
    }

    return (
        <div className='tw-min-h-screen tw-text-white md:tw-p-8'>
            <div className='tw-flex tw-items-center tw-gap-2 tw-mb-2'>
                <button onClick={() => window.history.back()} className="tw-text-2xl tw-flex tw-items-center">
                    <ion-icon name={"arrow-back-outline"} className="tw-mr-2" />
                </button>
                <h1 className='tw-text-xl md:tw-text-2xl tw-font-semibold tw-text-left tw-mt-2 tw-mb-2'>Bookings Details</h1>
            </div>
            {!booking && <div className="tw-flex tw-justify-center tw-items-center">
                <div className="tw-text-xl tw-font-bold tw-text-gray-400">
                    No Booking Found
                </div>
            </div>}

            {booking && (
                <div className="tw-space-y-4 tw-text-left">
                    <div className="tw-bg-[#2A2A2A] tw-rounded-lg tw-p-4">
                        <p className="tw-text-gray-400 tw-font-medium tw-mb-4">
                            Booking ID: <span className="tw-font-bold tw-text-white">{booking.id.toUpperCase()}</span>
                        </p>

                        {booking.event && (
                            <div className="tw-mt-6">
                                <h2 className="tw-text-lg tw-font-medium tw-mb-4">Event Details</h2>
                                <div className="tw-space-y-2">
                                    <p className="tw-flex tw-items-center tw-text-gray-400 tw-gap-2">
                                        <ion-icon icon={'pricetag'} /> Event Name: {booking?.event?.name}
                                    </p>
                                    <p className="tw-flex tw-items-center tw-text-gray-400 tw-gap-2">
                                        <ion-icon icon={'calendar'} /> Event Type: {_.startCase(booking.eventType)}
                                    </p>
                                    <p className="tw-flex tw-items-center tw-text-gray-400 tw-gap-2">
                                        <ion-icon icon={'calendar'} /> Date: {moment(booking.event.date).format('DD MMMM, YYYY')}
                                    </p>
                                    <p className="tw-flex tw-items-center tw-text-gray-400 tw-gap-2">
                                        <ion-icon icon={'time'} /> Time: {booking.event.time}
                                    </p>
                                    <p className="tw-flex tw-items-center tw-text-gray-400 tw-gap-2">
                                        <ion-icon icon={'location'} /> Location: {booking.event.location.address}, {booking.event.location.city}
                                    </p>
                                </div>
                            </div>
                        )}

                        <div className="tw-mt-6">
                            <h2 className="tw-text-lg tw-font-medium tw-mb-4">Guest Details</h2>
                            <div className="tw-space-y-2">
                                {booking.guestInfo.map((guest, index) => (
                                    <p key={index} className="tw-flex tw-items-center tw-text-gray-400 tw-gap-2">
                                        <ion-icon icon={'person'} /> {guest.name} ({guest.age}, {_.startCase(guest.gender)})
                                    </p>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="tw-bg-[#2A2A2A] tw-rounded-lg tw-p-4">
                        <h2 className="tw-text-lg tw-font-medium tw-mb-4">Payment Details</h2>

                        <div className="tw-space-y-3">
                            <div className="tw-flex tw-justify-between tw-items-center">
                                <p className="tw-flex tw-items-center tw-text-gray-400 tw-gap-2">
                                    <ion-icon icon={'card'} /> Base Fare ({booking.guestInfo.length} Participants)
                                </p>
                                <p>₹ {booking.paymentInfo.price.toFixed(2)}</p>
                            </div>

                            <div className="tw-flex tw-justify-between tw-items-center">
                                <p className="tw-flex tw-items-center tw-text-gray-400 tw-gap-2">
                                    <ion-icon icon={'card'} /> Tax ({booking.paymentInfo.taxPercentage}%)
                                </p>
                                <p>₹ {(booking.paymentInfo.totalPrice - booking.paymentInfo.price).toFixed(2)}</p>
                            </div>

                            <div className="tw-flex tw-justify-between tw-pt-2 tw-border-t tw-border-gray-600 tw-items-center">
                                <p className="tw-font-medium">Total Amount Paid</p>
                                <p className="tw-font-bold tw-text-orange-500">₹ {booking.paymentInfo.totalPrice.toFixed(2)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default BookingDetails