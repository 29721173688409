import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useRequest from '../../hooks/useRequest'
import { showToast, toastType } from '../../hooks/useToast'
import _ from 'lodash'

const HostBookingDetails = () => {

    const { partyId } = useParams()
    const [bookings, setBookings] = useState(null)
    const [loading, setLoading] = useState(true)
    const { makeRequest } = useRequest()

    useEffect(() => {
        fetchBooking()
    }, [])

    const fetchBooking = async () => {
        makeRequest({
            url: `/api/party/host/booking/party/${partyId}`,
            method: 'GET',
            onSuccess: ({ bookings }) => {
                setLoading(false)
                setBookings(bookings)
            },
            onError: (error) => {
                setLoading(false)
                showToast(error.message, toastType.ERROR)
            }
        })
    }

    if (loading) {
        return <div className="tw-flex tw-justify-center tw-items-center tw-h-screen">
            <div className="tw-w-16 tw-h-16 tw-border-t-4 tw-border-b-4 tw-border-gray-400 tw-rounded-full tw-animate-spin"></div>
        </div>
    }
    return (
        <div className='tw-min-h-screen tw-text-white tw-p-4'>
             <div className='tw-flex tw-items-center tw-gap-2 tw-mb-2'>
                <button onClick={() => window.history.back()} className="tw-text-2xl tw-flex tw-items-center">
                    <ion-icon name={"arrow-back-outline"} className="tw-mr-2" />
                </button>
                <h1 className='tw-text-xl md:tw-text-2xl tw-font-semibold tw-text-left tw-mt-2 tw-mb-2'>Party Bookings</h1>
            </div>

            {bookings?.length === 0 && (
                <h1 className='tw-flex tw-justify-center tw-text-center tw-text-gray-400 tw-text-lg'>
                    No bookings yet
                </h1>
            )}

            {bookings?.map((booking) => (
                <div
                    key={booking.id}
                    className="tw-bg-[#393737] tw-rounded-lg tw-p-4 tw-mb-6"
                >
                    <div className="tw-space-y-2 tw-text-left">
                        <div className='tw-flex tw-justify-between tw-items-center'>
                            <p className="tw-text-gray-300">Booking ID : {booking.id.toUpperCase()}</p>
                            <p className="tw-text-gray-300">Booking Date : {new Date(booking.createdAt).toLocaleString()}</p>
                        </div>
                        <div className="tw-w-full tw-bg-[#2A2A2A] tw-rounded-lg tw-p-4">
                            <h2 className="tw-text-lg tw-font-medium tw-mb-4">Guest Details</h2>
                            <div className="tw-space-y-2">
                                {booking.guestInfo.map((guest, index) => (
                                    <p key={index} className="tw-flex tw-items-center tw-text-gray-400 tw-gap-2">
                                        <ion-icon icon={'person'} /> {guest.name} ({guest.age}, {_.startCase(guest.gender)})
                                    </p>
                                ))}
                            </div>
                        </div>

                        <div className="tw-bg-[#2A2A2A] tw-rounded-lg tw-p-4">
                            <h2 className="tw-text-lg tw-font-medium tw-mb-4">Payment Details</h2>

                            <div className="tw-space-y-3">
                                <div className="tw-flex tw-justify-between tw-items-center">
                                    <p className="tw-flex tw-items-center tw-text-gray-400 tw-gap-2">
                                        <ion-icon icon={'card'} /> Base Fare ({booking.guestInfo.length} Participants)
                                    </p>
                                    <p>₹ {booking.paymentInfo.price.toFixed(2)}</p>
                                </div>

                                <div className="tw-flex tw-justify-between tw-items-center">
                                    <p className="tw-flex tw-items-center tw-text-gray-400 tw-gap-2">
                                        <ion-icon icon={'card'} /> Tax ({booking.paymentInfo.taxPercentage}%)
                                    </p>
                                    <p>₹ {(booking.paymentInfo.totalPrice - booking.paymentInfo.price).toFixed(2)}</p>
                                </div>

                                <div className="tw-flex tw-justify-between tw-pt-2 tw-border-t tw-border-gray-600 tw-items-center">
                                    <p className="tw-font-medium">Total Amount Paid</p>
                                    <p className="tw-font-bold tw-text-orange-500">₹ {booking.paymentInfo.totalPrice.toFixed(2)}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="tw-flex tw-items-center tw-gap-4">
                        <span className="tw-text-xl">₹ {booking?.paymentInfo.totalPrice.toFixed(2)}</span>
                    </div> */}
                </div>
            ))}
        </div>
    )
}

export default HostBookingDetails