import React, { useEffect, useState } from 'react'
import useRequest from '../../hooks/useRequest'
import PartyCard from '../../components/Party/PartyCard'
import { showToast, toastType } from '../../hooks/useToast'
import { useParams } from 'react-router-dom'

const HostParties = () => {

    const { id } = useParams()
    const [parties, setParties] = useState([])
    const [organizer, setOrganizer] = useState('')
    const { makeRequest } = useRequest()

    useEffect(() => {
        fetchParties()
    }, [id])

    const fetchParties = async () => {
        makeRequest({
            url: `/api/party/organizer/${id}/parties`,
            method: 'GET',
            onSuccess: ({ parties, organizer }) => {
                setParties(parties)
                setOrganizer(organizer)
            },
            onError: (error) => {
                showToast(error.message, toastType.ERROR)
            }
        })
    }

    const handleBackClick = () => {
        window.history.back()
    }

    return (
        <div className='tw-min-h-screen'>
            <div className=" tw-flex tw-justify-start tw-items-center tw-top-4 tw-left-4 tw-mb-6">
                <button onClick={handleBackClick} className="tw-text-white tw-text-2xl tw-flex tw-items-center tw-mr-4">
                    <ion-icon name={"arrow-back-outline"} className="tw-mr-2" />
                </button>
                <div className='tw-text-2xl tw-font-bold tw-text-white tw-my-4 tw-text-left'> Parties by {organizer?.name}</div>
            </div>
            {parties?.length === 0 && <h1 className='tw-flex tw-justify-center tw-text-center tw-text-gray-400 tw-text-lg'>Host has no upcoming parties</h1>}
            <div className='tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4 tw-mt-3'>
                {parties?.map((party) => (
                    <PartyCard key={party.id} party={party} />
                ))}
            </div>
        </div>
    )
}

export default HostParties