import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDFzdp2GY1Ind2nAnJJOXRdADTIk2uA5EU",
  authDomain: "partyconnecthub-0.firebaseapp.com",
  projectId: "partyconnecthub-0",
  storageBucket: "partyconnecthub-0.appspot.com",
  messagingSenderId: "523650927201",
  appId: "1:523650927201:web:6c75ea6fa0c11c9b02b820",
  measurementId: "G-MXXZ3KHDEQ"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };