export const formatDate = (date) => {
    if (!date) return 'TBA';

    const parsedDate = new Date(date);
    
    const options = {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    };

    return parsedDate.toLocaleDateString('en-IN', options);
};

export const formatTime = (time, hour12 = true) => {
    if (!time) return 'TBA';

    const [hours, minutes] = time.split(':').map(Number);
    
    const date = new Date();
    date.setHours(hours, minutes, 0);

    const options = {
        hour: '2-digit',
        minute: '2-digit',
        hour12
    };

    return date.toLocaleTimeString('en-IN', options);
};