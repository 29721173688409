import { toast } from 'react-hot-toast';

export const toastType = {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    LOADING: 'loading',
    CUSTOM: 'custom'
}

export const showToast = (message, type, theme = 'dark') => {
    const commonOptions = {
        duration: 3000,
        position: 'top-center',
        style: theme === 'dark' ? {
            background: '#333',
            color: '#fff',
        } : {
            background: '#fff',
            color: '#000',
        },
    }

    switch (type) {
        case 'success':
            toast.success(message, commonOptions);
            break;
        case 'error':
            toast.error(message, commonOptions);
            break;
        case 'loading':
            toast.loading(message, commonOptions);
            break;
        case 'custom':
            toast(message, commonOptions);
            break;
        default:
            toast(message, commonOptions);
            break;
    }
}